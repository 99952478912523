.project_card {
  border-radius: var(--border-round);
  overflow: hidden;
}

.project_card-half {
  flex-basis: 45%;
  flex-grow: 1;
  aspect-ratio: 1;
}

.project_card-full {
  flex-basis: 100%;
  aspect-ratio: 2 / 1;
}

.project_card-text {
  display: flex;
  align-items: center;
  padding: calc(var(--card-padding) * 4);
  justify-content: center;
  text-align: center;
}

.project_card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_card-design {
  background-color: var(--blue);
}

.project_card-code {
  background-color: var(--mint);
}

.project_card-photo {
  background-color: var(--brown);
}

@media (max-width: 600px) {
  .project_card-half {
    flex-basis: 100%;
    flex-grow: 1;
  }

  .project_card-text {
    aspect-ratio: 2/1;
  }
}
