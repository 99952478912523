.about {
  padding-top: 0px;
}

.about__header {
  max-width: 850px;
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: var(--tile-gap);
  padding: calc(var(--site-padding) * 2) 0px;
}

.about__content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--site-padding) * 2);
}

@media (max-width: 600px) {
  .about__header {
    max-width: 300px;
    padding: calc(var(--site-padding) * 4) 0px;
  }
}

/* Intro Section */

.about__intro {
  display: flex;
  gap: calc(var(--tile-gap) * 10);
}

.about__intro-para {
  display: flex;
  flex-direction: column;
  gap: var(--tile-gap);
  flex-basis: 50%;
  flex-grow: 1;
}

.about__intro img {
  flex-basis: 40%;
  max-width: 40%;
  aspect-ratio: 1;
  flex-grow: 1;
  object-fit: cover;
  border-radius: var(--border-round);
}

@media (max-width: 600px) {
  .about__intro {
    flex-direction: column-reverse;
    gap: calc(var(--tile-gap) * 5);
  }
  .about__intro img {
    flex-basis: 100%;
    max-width: 100%;
  }
}

/* Stats Section */

.about__stats {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.about__stats_column {
  display: flex;
  flex-direction: column;
  flex-basis: 32%;
  max-width: 32%;
  flex-grow: 1;
  gap: calc(var(--tile-gap) / 2);
}

.about__stats_column h4 {
  padding-bottom: calc(var(--tile-gap) / 2);
}

.about__stats_services {
  display: flex;
  flex-direction: column;
  gap: var(--tile-gap);
}

.about__stats_services-tags {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--tile-gap) / 2);
  width: 100%;
}

.about__stats_subsection {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 4px;
}

.about__stats_experience {
  margin-top: -4px;
  line-height: 0.5;
  color: var(--grey-dark);
}

.resume_line {
  color: var(--grey-dark);
  padding-top: var(--tile-gap);
}

@media (max-width: 600px) {
  .about__stats {
    flex-direction: column;
    gap: calc(var(--site-padding) * 2);
  }

  .about__stats_services-tags {
    gap: var(--tile-gap);
  }

  .about__stats_column {
    flex-basis: 100%;
    max-width: 100%;
    gap: var(--tile-gap);
  }

  .about__stats_experience {
    margin-top: 0px;
    line-height: 1;
    color: var(--grey-dark);
  }
}
