.tag-small {
  padding: 5px 8px;
  border-radius: 10px;
  width: fit-content;
}

.tag-large {
  padding: 8px 15px;
  border-radius: 25px;
  width: fit-content;
}

.tag-code {
  background-color: var(--mint);
  color: var(--mint-dark);
  transition: 0.2s;
}

.tag-code:hover {
  color: var(--mint);
  background-color: var(--mint-dark);
}

.tag-design {
  background-color: var(--blue);
  color: var(--blue-dark);
  transition: 0.2s;
}

.tag-design:hover {
  color: var(--blue);
  background-color: var(--blue-dark);
}

.tag-photo {
  background-color: var(--brown);
  color: var(--brown-dark);
  transition: 0.2s;
}

.tag-photo:hover {
  color: var(--brown);
  background-color: var(--brown-dark);
}
