.project_tile {
  flex-basis: 32%;
  flex-grow: 1;
  max-width: 32%;
  display: flex;
  flex-direction: column;
  gap: var(--tile-gap);
}

@media (max-width: 900px) {
  .project_tile {
    flex-basis: 48%;
    max-width: 48%;
  }
}

@media (max-width: 600px) {
  .project_tile {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.project_tile__image {
  width: 100%;
  overflow: hidden;
  aspect-ratio: 1;
  border-radius: var(--border-round);
}

.project_tile__image-design {
  background-color: var(--blue);
}

.project_tile__image-code {
  background-color: var(--mint);
}

.project_tile__image-photo {
  background-color: var(--brown);
}

.project_tile__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project_tile__details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.project_tile__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  display: flex;
  gap: var(--tag-gap);
}
