.project__header {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 20px;
  max-width: 800px;
  padding: var(--site-padding) 0px;
}

.project__tags {
  display: flex;
  align-items: center;
  gap: var(--tag-gap);
  flex-wrap: wrap;
  justify-content: center;
}

.project__intro {
  padding: var(--site-padding) var(--site-padding) 0px;
  max-width: 800px;
  margin: auto;
}

.project__links {
  display: flex;
  gap: var(--tile-gap);
  padding: var(--site-padding) var(--site-padding) 0px;
}

.project__link {
  color: var(--grey-dark);
  padding-bottom: 0.2em;
  border-bottom: 1px solid var(--grey-dark);
}

.project__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: var(--tile-gap);
}

.project__credits {
  display: flex;
  flex-direction: column;
  gap: var(--tile-gap);
  width: 100%;
  align-items: center;
  text-align: center;
  padding: var(--site-padding) 0px;
}

.project__credits_single {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: fit-content;
}

.project__credits_single h4 {
  color: var(--grey-dark);
}
