.nav {
  padding-bottom: 0;
}

.nav__items {
  padding-bottom: calc(var(--tile-gap) * 2);
  border-bottom: 1px solid var(--black);
}

.nav__links {
  display: flex;
  gap: 25px;
}

.nav__links-hamburger {
  display: none;
}

@media (max-width: 600px) {
  .nav__items {
    display: flex;
    align-items: center;
  }

  .nav__items h4 {
    padding-left: var(--tile-gap);
  }

  .nav__links {
    display: none;
  }

  .nav__links-hamburger {
    display: block;
  }

  .nav__links-close {
    position: fixed;
    top: var(--site-padding);
    right: var(--site-padding);
  }

  .nav__links-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    display: flex;
    color: white;
  }
}
