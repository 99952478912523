.home {
  padding-top: 0px;
}

.home__header {
  max-width: 850px;
  margin: auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: calc(var(--site-padding) * 2) 0px;
}

.home__projects {
  display: flex;
  flex-direction: column;
  gap: calc(var(--tile-gap) * 2);
}

@media (max-width: 600px) {
  .home__header {
    padding: calc(var(--site-padding) * 4) 0px;
  }
}
