.filtered {
  padding-top: 0px;
}

.filtered__header {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: calc(var(--site-padding) * 2) 0px;
  gap: var(--tile-gap);
}

@media (max-width: 600px) {
  .filtered__header {
    padding: calc(var(--site-padding) * 4) 0px;
  }
}
