@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,300&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Plus Jakarta Sans";
}

html,
body,
body #root {
  height: 100%;
}

/* Variables */

:root {
  --site-padding: 50px;
  --card-padding: 20px;
  --border-round: 8px;
  --tile-gap: 16px;
  --tag-gap: 8px;
  --mint: #dfe9db;
  --mint-dark: #8fa595;
  --blue: #d9e4e9;
  --blue-dark: #8fa1a5;
  --blue-pop: #0078ef;
  --brown: #e9e2d9;
  --brown-dark: #a59c8f;
  --grey: #f3f3f3;
  --grey-dark: #a8a8a8;
  --black: #000000;
}

@media (max-width: 900px) {
  :root {
    --site-padding: 35px;
  }
}

@media (max-width: 600px) {
  :root {
    --site-padding: 20px;
    --card-padding: 10px;
    --border-round: 8px;
    --tile-gap: 8px;
    --tag-gap: 4px;
  }
}

/* Font Styling */

h1 {
  font-size: 50px;
  font-weight: 300;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  line-height: 1.1;
}

h2 {
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  line-height: 1.1;
}

h3 {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.1;
}

h4 {
  font-size: 10.5px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

h5 {
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

h6 {
  font-size: 24px;
  font-weight: 400;
}

p {
  font-family: "Merriweather", serif;
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
}

.p-large {
  font-family: "Merriweather", serif;
  font-size: 35px;
  font-weight: 300;
  font-style: italic;
  letter-spacing: -0.01em;
  line-height: 1.1;
}

a {
  text-decoration: none;
  color: var(--black);
  transition: 0.2s;
}

a:hover {
  color: var(--blue-pop);
}

.hover__link {
  color: var(--black);
  transition: 0.2s;
}

.hover__link:hover {
  color: var(--blue-pop);
}

@media (max-width: 900px) {
  .p-large {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 25px;
    font-weight: 400;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 10px;
  }

  h5 {
    font-size: 8px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
  }
}

/* Standard Styling */

.App {
  height: 100%;
  text-align: left;
  color: black;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}

.App::-webkit-scrollbar {
  display: none;
}

.content {
  width: 100%;
  padding: var(--site-padding);
}

.content-offset {
  padding: var(--site-padding);
}

.clickable {
  cursor: pointer;
}

.horz-elements {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .content-offset {
    padding-left: 0;
    padding-right: 0;
  }
}
