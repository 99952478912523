.footer {
  background-color: var(--grey);
}

.footer__main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer__links {
  display: flex;
  gap: calc(var(--tile-gap) * 2);
  padding-right: var(--site-padding);
}

.footer__links-stack {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 120px;
}

.footer__name {
  width: 100%;
}

@media (max-width: 600px) {
  .footer__main {
    flex-direction: column;
    gap: 30px;
  }
  .footer__links {
    width: 100%;
  }
  .footer__links-stack {
    width: 50%;
  }
}
